<div *ngIf="body.body" class="body">
  <app-progress [loading]="loading"></app-progress>
  <div class="body_text" [ngStyle]="{'height': height}" [ngClass]="{'fixed':fixed, 'border':body.body.length>0}">
    <div class="body_text__close" *ngIf="isMobile()" (click)="close()"> &#x2715;</div>
    <app-news-text [isImportant]="isImportant()" [date]="body.date" [text]="body.body" [isDisclosure]="body.isDisclosure"></app-news-text>
    <div class="body_text_links" *ngIf="body.title">
      <a class="body_text_links_link" routerLink="/news/{{body.id}}" target="_blank">Ссылка на новость</a>
    </div>
  </div>
</div>
