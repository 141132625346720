import { Component, HostListener, OnInit } from '@angular/core';
import { WebSocketService } from '../../Services/Ws/web-socket.service';
import * as moment from 'moment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';


export interface NewsFilter {
  categories: number[],
  startDate: Date,
  endDate: Date,
  searchString: string,
  isFullTextSearch: boolean,
  domain: string,
  topNewsId: number,
  bottomNewsId: number
}

export interface NewsTitle {
  id: number,
  title: string,
  priority: number,
  pushed: boolean,
  viewed: boolean,
  date: Date,
  categories: number[],
  showDate: boolean,
  domain: string
}

export interface NewsBody {
  id: number,
  title: string;
  body: string;
  priority: number;
  date: string,
  isDisclosure: boolean
}

export interface NewsCount {
  newsCount: number
}

@Component({
  selector: 'app-main-news',
  templateUrl: './main-news.component.html',
  styleUrls: ['./main-news.component.less']
})

export class MainNewsComponent implements OnInit {

  pushNews = (n: NewsTitle) => {
  };
  reconnect = (connected: boolean) => {
    console.log('Connected: ' + connected);
  };

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden) {

      console.log("Page is hidden");
      this.wss.disconnect();
    } else {
      console.log("Page is visible");
      this.wss.connect();
    }
  }


  constructor(private wss: WebSocketService, private oauthService: OAuthService, private router: Router) {
    wss.onMessage().subscribe(message => {
      let news = JSON.parse(JSON.stringify(message));
      this.pushNews({
        id: news.id,
        viewed: false,
        pushed: true,
        date: moment().toDate(),
        title: news.title,
        priority: news.priority,
        showDate: true,
        categories: news.categories.split(','),
        domain: news.domain
      })
    });
    wss.onConnect().subscribe(x => this.reconnect(x));
  }

  ngOnInit() {
    let claims = this.oauthService.getIdentityClaims();
    console.log('claims', claims)
    if ((this.router.url == '/' || this.router.url == '') && this.oauthService.hasValidIdToken()) {
      // TODO переход в зависимости от клеймов
      console.log('переход в зависимости от клеймов');
      this.router.navigateByUrl('business');
    }
  }

  routerActivate = (e) => {
    if (e.pushNews) {
      this.pushNews = e.pushNews;
    }
    if (e.reconnect) {
      this.reconnect = e.reconnect;
    }
  }

}
