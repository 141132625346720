export const environment = {
  production: false,
  baseUrl: 'https://comm-back.birweb.1prime.ru',
  wssUrl: 'wss://comm-wss.birweb.1prime.ru/wss',
  auth: {
    issuer: 'http://svc-5080.birweb-qa.1prime.ru',
    clientId: 'commerce',
    scope: 'openid commerce',
    showDebugInformation: true,
    redirectUri: 'https://commerce.birweb-qa.1prime.ru/oauth2callback',
    responseType: 'code',
    tokenEndpoint: 'https://svc-5080.birweb-qa.1prime.ru/connect/token',
    logoutUrl: 'https://svc-5080.birweb-qa.1prime.ru/Account/Logout',
    loginUrl: 'https://svc-5080.birweb-qa.1prime.ru/connect/authorize',
    postLogoutRedirectUri: 'https://commerce.birweb-qa.1prime.ru',
    requireHttps: false,
    sessionChecksEnabled: true,
  }
};

