import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsTitle } from '../main-news/main-news.component';
import { LocalStorageService } from '../../Services/LocalStorageService/local-storage.service';

@Component({
  selector: 'app-news-title',
  templateUrl: './news-title.component.html',
  styleUrls: ['./news-title.component.less']
})
export class NewsTitleComponent implements OnInit {

  @Input() news: NewsTitle;
  @Input() odd: boolean;
  @Input() selected: boolean;
  @Input() viewed: boolean;
  @Input() pushed: boolean;
  @Input() showDate: boolean;
  @Output() newsClicked: EventEmitter<number> = new EventEmitter<number>();


  constructor(private lss: LocalStorageService) {
  }

  ngOnInit(): void {
  }

  onClick = () => {
    this.viewed = true;
    this.pushed = false;
    this.lss.addViewed(this.news.id);
    this.lss.removePushed(this.news.id);
    this.newsClicked.emit(this.news.id);
  };

  isImportant = () => this.news.priority == 2 || (this.news.domain != "RBL" && this.news.title.indexOf('*') == 0);

}
