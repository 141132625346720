import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNewsComponent } from './main-news/main-news.component';
import { BaseFilterComponent } from './base-filter/base-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { CalendarComponent } from './calendar/calendar.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryFilterComponent } from './category-filter/category-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NewsTitleComponent } from './news-title/news-title.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsBodyComponent } from './news-body/news-body.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FooterComponent } from './footer/footer.component';
import { NewsTextComponent } from './news-text/news-text.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { RouterModule } from '@angular/router';
import { EditPageComponent } from './edit-page/edit-page.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DeletePageComponent } from './delete-page/delete-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { NotFoundTextComponent } from './not-found-text/not-found-text.component';
import { ProgressComponent } from './progress/progress.component';
import { TabComponent } from './tab/tab.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [MainNewsComponent, BaseFilterComponent, CalendarComponent, CategoryFilterComponent, NewsTitleComponent, NewsListComponent, NewsBodyComponent, FooterComponent, NewsTextComponent, NewsPageComponent, EditPageComponent, DeletePageComponent, NotFoundPageComponent, NotFoundTextComponent, ProgressComponent, TabComponent, LoginComponent, LogoutComponent],
  exports: [
    MainNewsComponent,
    FooterComponent
  ],
	imports: [
		CommonModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatNativeDateModule,
		MatInputModule,
		NgxDaterangepickerMd.forRoot(),
		MatButtonModule,
		MatIconModule,
		MatDialogModule,
		FormsModule,
		MatCheckboxModule,
		MatProgressBarModule,
		RouterModule,
		AngularEditorModule,
		ReactiveFormsModule
	]
})
export class ComponentsModule {
}
