import { Injectable } from '@angular/core';
import { Category } from '../../Components/category-filter/category-filter.component';

export interface FilterSettings {
  categories: [Category[], Category[], Category[], Category[]];
  domain: string;
}


@Injectable({
  providedIn: 'root'
})

export class FilterService {

  constructor() {
  }

  getFilterSettings = (kind: string): FilterSettings => {
    switch (kind.toLowerCase()) {
      case 'business':
        return {
          categories: [
            [{ category: 180, checked: true, enabled: true, name: 'Бизнес-лента' },
              { category: 153, checked: true, enabled: true, name: 'Рынки' },
              { category: 170, checked: true, enabled: true, name: 'Продовольствие' },
              { category: 108, checked: true, enabled: true, name: 'Страхование' }],
            [{ category: 151, checked: true, enabled: true, name: 'Экономика' },
              { category: 4, checked: true, enabled: true, name: 'Энергетика' },
              { category: 19, checked: true, enabled: true, name: 'Металлургия' }],
            [{ category: 107, checked: true, enabled: true, name: 'Банки' },
              { category: 160, checked: true, enabled: true, name: 'Транспорт' },
              { category: 79, checked: true, enabled: true, name: 'Золото' }],
            [{ category: 12, checked: true, enabled: true, name: 'Раскрытие информации' }
            ]],
          domain: 'RBL'
        };
      case 'forex':
        return {
          categories: [
            [{ category: 3, checked: true, enabled: true, name: 'Обзоры и аналитика' },
              { category: 4, checked: true, enabled: true, name: 'Говорят на рынке' }],
            [{ category: 5, checked: true, enabled: true, name: 'Макроэкономика и политика' },
              { category: 6, checked: true, enabled: true, name: 'Календарь событий' }],
            [{ category: 11, checked: true, enabled: true, name: 'Котировки и цены' }],
            []],
          domain: 'DJF'
        };
      case 'stocks':
        return {
          categories: [
            [{ category: 31, checked: true, enabled: true, name: 'Обзоры и аналитика' },
              { category: 32, checked: true, enabled: true, name: 'Говорят на рынке' }],
            [{ category: 33, checked: true, enabled: true, name: 'Макроэкономика и политика' },
              { category: 34, checked: true, enabled: true, name: 'Календарь событий' }],
            [{ category: 30, checked: true, enabled: true, name: 'Отчеты и новости компаний' },
              { category: 35, checked: true, enabled: true, name: 'Ход торгов' }
            ],
            []],
          domain: 'DJS'
        };
      case 'commodities':
        return {
          categories: [
            [{ category: 43, checked: true, enabled: true, name: 'Обзоры и аналитика' },
              { category: 40, checked: true, enabled: true, name: 'Говорят на рынке' }],
            [{ category: 42, checked: true, enabled: true, name: 'Макроэкономика и политика' },
              { category: 41, checked: true, enabled: true, name: 'Календарь событий' }],
            [{ category: 44, checked: true, enabled: true, name: 'Котировки и цены' }],
            []],
          domain: 'DJC'
        };
    }
    return {
      categories: [[], [], [], []],
      domain: ''
    };

  }

}
