import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainNewsComponent } from '../Components/main-news/main-news.component';
import { NewsPageComponent } from '../Components/news-page/news-page.component';
import { EditPageComponent } from '../Components/edit-page/edit-page.component';
import { DeletePageComponent } from '../Components/delete-page/delete-page.component';
import { NotFoundPageComponent } from '../Components/not-found-page/not-found-page.component';
import { TabComponent } from '../Components/tab/tab.component';
import { AuthGuardService } from '../auth/auth-guard.service';
import { AppComponent } from './app.component';
import { LoginComponent } from '../Components/login/login.component';
import { LogoutComponent } from '../Components/logout/logout.component';


const routes: Routes = [
  { path: 'oauth2callback', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {

    path: '', component: MainNewsComponent, children: [
      { path: 'forex', component: TabComponent, canActivate: [AuthGuardService] },
      { path: 'business', component: TabComponent, canActivate: [AuthGuardService] },
      { path: 'stocks', component: TabComponent, canActivate: [AuthGuardService] },
      { path: 'commodities', component: TabComponent, canActivate: [AuthGuardService] },
    ], canActivate: [AuthGuardService]
  },
  { path: 'news/:id', component: NewsPageComponent, canActivate: [AuthGuardService] },
  { path: 'edit/:id', component: EditPageComponent, canActivate: [AuthGuardService] },
  { path: 'delete/:id', component: DeletePageComponent, canActivate: [AuthGuardService] },
  { path: '**', component: NotFoundPageComponent, canActivate: [AuthGuardService] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
