import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ApiService } from '../../Services/Api/api.service';
import { NewsBody } from '../main-news/main-news.component';
import { ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'app-news-body',
  templateUrl: './news-body.component.html',
  styleUrls: ['./news-body.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NewsBodyComponent implements OnInit {

  @Input() body: NewsBody;
  @Input() loading: boolean;
  fixed: boolean = false;
  height: string;

  constructor() {
  }

  ngOnInit(): void {
    this.height = this.getHeight();
  }

  ngOnChanges(changes) {
    this.fixed = (window.scrollY > 400 && this.body?.body?.length > 0) || this.isMobile();
    this.height = this.getHeight();
    console.log('ngOnChanges', changes);
  }

  @HostListener('window:scroll', ['$event']) onScroll($event: Event): void {
    if ($event) {
      this.fixed = window.scrollY > 400 && this.body?.body?.length > 0;
    }
    this.height = this.getHeight();
  }

  @HostListener('window:resize', ['$event']) onResize($event: Event): void {
    if ($event) {
      this.fixed = window.scrollY > 400 || this.isMobile();
    }
    this.height = this.getHeight();
  }

  getHeight = (): string => {
    if (this.body.body) {
      if(this.isMobile()){
        return window.innerHeight + 'px';
      }
      if (window.scrollY < 400) {
        return (window.innerHeight - 50 - (400 - window.scrollY)) + 'px';
      } else {
        let isBottom = (document.body.scrollHeight - window.innerHeight - window.scrollY - 178) < 0;
        if (isBottom) {
          return (window.innerHeight - 50) + (document.body.scrollHeight - window.innerHeight - window.scrollY - 178) + 'px';
        } else {
          return (window.innerHeight - 50) + 'px';
        }
      }
    }
    return '0px';
  };
  isImportant = () => this.body.priority == 2 || this.body.title.indexOf('*') == 0;
  close = () => {
    this.body.body = '';
    this.height = '0px';
  }

  isMobile = () => window.innerWidth<600;
}
