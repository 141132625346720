import { Injectable } from '@angular/core';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { delayWhen, distinctUntilChanged, Observable, Observer, retryWhen, share, Subject, takeUntil, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private disconnect$: Subject<any> = new Subject<any>();

// объект конфигурации WebSocketSubject
  private config: WebSocketSubjectConfig<string>;

  private websocket$: WebSocketSubject<string>;

// сообщает, когда происходит коннект и реконнект
  private connection$: Observer<boolean>;

// вспомогательный Observable для работы с подписками на сообщения
  private wsMessages$: Subject<string>;


// статус соединения
  public status: Observable<boolean>;

  constructor(private oauthService: OAuthService) {
    this.wsMessages$ = new Subject<string>();

    // при сворачивании коннекта меняем статус connection$ и глушим websocket$
    this.config = {
      url: `${environment.wssUrl}?x-access-token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiZXhjaGFuZ2UifQ.8JanhJeppNKPkHTr2IEhEvrKp9NNFvqrsoSBW-2jWjQ`,

      //url: 'ws://localhost:3001',
      closeObserver: {
        next: (e: CloseEvent) => {
          this.websocket$ = null;
          this.connection$.next(false);
        }
      },
      // при коннекте меняем статус connection$
      openObserver: {
        next: (e: Event) => {
          this.connection$.next(true);
        }
      }
    };
    // connection status
    this.status = new Observable<boolean>((observer) => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    this.connect();
  }

  public connect(): void {
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiZXhjaGFuZ2UifQ.lHLnXO-MDYfMYVpRK8vN9tFw174k7g7saNKs7JCzptc';
    if (token) {
      this.config.url = `${environment.wssUrl}?x-access-token=${token}`;
      if (!this.websocket$) {
        this.websocket$ = new WebSocketSubject(this.config); // создаем
        this.websocket$
          .pipe(
            retryWhen(errors =>
              errors.pipe(
                delayWhen(x => timer(2000))
              )
            ),
            takeUntil(this.disconnect$),
          ).subscribe(message => this.wsMessages$.next(message));
      }
    }
  }


  public disconnect = () => this.disconnect$.next(null);

  public onMessage = (): Observable<string> => this.wsMessages$;

  public onConnect = (): Observable<boolean> => this.status;

}
