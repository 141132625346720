import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LocalStorageService } from '../Services/LocalStorageService/local-storage.service';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private lss: LocalStorageService, private oauthService: OAuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloned = req.clone({
        headers: req.headers.set("X-Access-Token", `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiZXhjaGFuZ2UifQ.8JanhJeppNKPkHTr2IEhEvrKp9NNFvqrsoSBW-2jWjQ`)
      });
      return next.handle(cloned);
  }
}
